import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Image from '@activebrands/core-web/components/Image';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import OverlayHeader from 'components/overlays/OverlayHeader';

const Wrapper = styled('div', {
    position: 'relative',
    background: 'var(--color-border-size-guide)',
    margin: '4px',
    width: 'var(--width-overlays)',
    height: `calc(var(--vh) - var(--height-header) - 8px)`, //8px since there is no gap around header on mobile
    overflow: 'hidden',

    [media.min['desktop.sm']]: {
        margin: '8px 4px',
        height: `calc(var(--vh) - var(--height-header) - 12px)`,
    },
});

const InnerWraper = styled('div', {
    alignItems: 'space-between',
    background: 'var(--color-bg)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    width: '100%',
});

const OverlayFooter = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    alignItems: 'center',
    padding: '16px',
    borderTop: '1px solid var(--color-border-size-guide)',
});

const SizeGuideOverlay = ({
    defaultActiveGuide = '',
    howToMeasureSrc = '',
    sizeguideLabel = '',
    sizeguideSrc = '',
}) => {
    const [activeGuide, setActiveGuide] = useState(
        defaultActiveGuide || (sizeguideSrc ? 'size-guide' : 'how-to-measure-guide')
    );

    const buttonStyles = {
        width: 'calc(50% - 4px)',
        justifyContent: 'center',
        ...textStyles['Misc/14_100_-1_500_UC'],
    };

    return (
        <Wrapper>
            <InnerWraper>
                <OverlayHeader $style={{ marginBottom: '8px' }} headingText={sizeguideLabel} overlayId="sizeguide" />
                <ScrollableBox $style={{ flexGrow: 1 }}>
                    <div>
                        <Image
                            $style={{ margin: '0 auto 32px', width: ['100%', '360px', 'calc(420px - 24px)'] }} // 24px padding
                            src={{
                                url: activeGuide === 'size-guide' ? sizeguideSrc : howToMeasureSrc,
                                width: [420, 840],
                            }}
                            sizes="420px"
                        />
                    </div>
                </ScrollableBox>
                {sizeguideSrc && howToMeasureSrc && (
                    <OverlayFooter>
                        {sizeguideSrc && (
                            <ThemeButton
                                $style={buttonStyles}
                                active={activeGuide === 'size-guide'}
                                theme="outlined"
                                onClick={() => setActiveGuide('size-guide')}
                            >
                                {fm('Size guide')}
                            </ThemeButton>
                        )}
                        {howToMeasureSrc && (
                            <ThemeButton
                                $style={buttonStyles}
                                active={activeGuide === 'how-to-measure-guide'}
                                theme="outlined"
                                onClick={() => setActiveGuide('how-to-measure-guide')}
                            >
                                {fm('How to measure')}
                            </ThemeButton>
                        )}
                    </OverlayFooter>
                )}
            </InnerWraper>
        </Wrapper>
    );
};

SizeGuideOverlay.propTypes = {
    defaultActiveGuide: PropTypes.string,
    howToMeasureSrc: PropTypes.string,
    sizeguideLabel: PropTypes.string.isRequired,
    sizeguideSrc: PropTypes.string,
};

export default SizeGuideOverlay;
